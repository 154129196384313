html,
body {
	background-color: #ffffff;
	height: 100%;
}

#root {
	height: 100%;
}

.App {
	min-height: 100vh;
	display: flex;
	flex-flow: column nowrap;
	/* align-items: center; */
	justify-content: center;
	background-color: #ffffff;
}

/* Custom scrollbar */
::-webkit-scrollbar {
	width: 6px;
}

::-webkit-scrollbar-track {
	border-radius: 3px;
}

::-webkit-scrollbar-thumb {
	border-radius: 9px;
	background-color: #009688;
	/* border-left: 3px solid white;
  border-right: 3px solid white; */
}

.logo-animation {
	animation: fade 2.5s infinite ease-in-out;
}

@keyframes fade {
	0% {
		opacity: 0;
	}
	80% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

@font-face {
	font-family: Merriweather;
	src: url("../fonts/Merriweather-Regular.ttf");
}
@font-face {
	font-family: Merriweather;
	src: url("../fonts/Merriweather-Bold.ttf");
	font-weight: bold;
}
@font-face {
	font-family: Merriweather;
	src: url("../fonts/Merriweather-BoldItalic.ttf");
	font-weight: bold;
	font-style: italic;
}
@font-face {
	font-family: Merriweather;
	src: url("../fonts/Merriweather-Italic.ttf");
	font-style: italic;
}
@font-face {
	font-family: Merriweather;
	src: url("../fonts/Merriweather-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: Merriweather;
	src: url("../fonts/Merriweather-LightItalic.ttf");
	font-weight: 300;
	font-style: italic;
}
@font-face {
	font-family: Merriweather;
	src: url("../fonts/Merriweather-Black.ttf");
	font-weight: 900;
}
@font-face {
	font-family: Merriweather;
	src: url("../fonts/Merriweather-BlackItalic.ttf");
	font-weight: 900;
	font-style: italic;
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-Regular.ttf");
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-Italic.ttf");
	font-style: italic;
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-BoldItalic.ttf");
	font-style: italic;
	font-weight: bold;
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-Bold.ttf");
	font-weight: bold;
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-Light.ttf");
	font-weight: 300;
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-LightItalic.ttf");
	font-style: italic;
	font-weight: 300;
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-BlackItalic.ttf");
	font-style: italic;
	font-weight: 900;
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-Black.ttf");
	font-weight: 900;
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-MediumItalic.ttf");
	font-style: italic;
	font-weight: 500;
}
@font-face {
	font-family: Nunito;
	src: url("../fonts/Nunito-Medium.ttf");
	font-weight: 500;
}
