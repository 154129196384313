@import url("https://fonts.googleapis.com/css?family=Open+Sans");

.wrapper {
	width: 400px;

	box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
	border-radius: 0.3rem;
}

.error {
	margin: 0;
	font-size: 90%;
	color: tomato;
}

.tag-item {
	background-color: teal;
	display: inline-block;
	font-size: 14px;
	border-radius: 30px;
	height: 30px;
	padding: 0 4px 0 1rem;
	display: inline-flex;
	align-items: center;
	margin: 0 0.3rem 0.3rem 0;
	color: "white";
}

.tag-item > .button {
	background-color: white;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	border: none;
	cursor: pointer;
	font: inherit;
	margin-left: 10px;
	font-weight: bold;
	padding: 0;
	line-height: 1;
	display: flex;
	align-items: center;
	justify-content: center;
	color: "white";
}
